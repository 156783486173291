
























































































































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import VModal from "vue-js-modal";

import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ConfirmUpdateRequest,
} from "@/proto/user/all_pb";
import { MFA } from "@/proto/mfa/all_pb";
import { UserInfo } from "@/types";
import { TabLayout } from "@/layouts";
import { CustomInput, Loader, NotificationModal, OTPModal } from "@/components";

const userModule = namespace("user");

Vue.use(Vuelidate);
Vue.use(VModal);

@Component({
  components: {
    TabLayout,
    CustomInput,
    Loader,
    NotificationModal,
    OTPModal,
  },
  validations: {
    currentPass: {
      required,
    },
    newPass: {
      required,
      mustHaveLetter: (value) => {
        const pattern = /[a-z]/i;
        return pattern.test(value);
      },
      mustHaveNumber: (value) => {
        const pattern = /[0-9]/;
        return pattern.test(value);
      },
      mustHaveSpecial: (value) => {
        const pattern = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        return pattern.test(value);
      },
      minLength: minLength(8),
      maxLength: maxLength(30),
    },
    confirmPass: {
      required,
      sameAsPassword: sameAs("newPass"),
    },
  },
})
export default class AccountSecurity extends Vue {
  @userModule.Getter("currentUserInfo") userInfo!: UserInfo;
  @userModule.Action("changePassword") changePassword!: (
    payload: ChangePasswordRequest.AsObject
  ) => ChangePasswordResponse.AsObject;
  @userModule.Action("confirmChangePassword") confirmChangePassword!: (
    payload: ConfirmUpdateRequest.AsObject
  ) => void;

  currentPass = "";
  newPass = "";
  confirmPass = "";
  loading = false;
  mfaEventID = "";
  error = "";
  otpError = "";
  mustShowNotif = false;
  notifText = "";

  get saveButtonText(): string {
    return this.loading ? "Saving New Password" : "Save";
  }

  onOTPModalClose() {
    this.$modal.hide("otp-modal");
  }

  showOTPModal() {
    this.otpError = "";
    this.$modal.show("otp-modal");
  }

  closeNotifModal() {
    this.mustShowNotif = false;
  }

  showNotifModal(text: string) {
    this.notifText = text;
    this.mustShowNotif = true;
  }

  async onOTPCodeSubmit(code: string) {
    try {
      const payload: ConfirmUpdateRequest.AsObject = {
        userid: this.userInfo.id as string,
        mfaeventid: this.mfaEventID,
        mfatype: MFA.EMAIL,
        mfatoken: code,
      };

      await this.confirmChangePassword(payload);
      this.showNotifModal("Password changed successfully");
      this.onOTPModalClose();
    } catch (error) {
      const err = error as Error;
      this.otpError = err.message;
    }
  }

  async onChangePassword() {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      const result = await this.changePassword({
        oldpassword: this.currentPass,
        newpassword: this.newPass,
        userid: this.userInfo.id as string,
        eventid: "",
      });

      this.mfaEventID = result.mfaeventid;
      this.showOTPModal();
      this.resetState();
      this.$v.$reset();
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }

  resetState() {
    this.currentPass = "";
    this.newPass = "";
    this.confirmPass = "";
    this.error = "";
    this.otpError = "";
  }
}
